<template>
  <div class="container">
    <template v-if="collection.res.length !== 0">
      <el-row style="width: 100%; height: 100%;">
        <el-col
          :sm="24"
          :lg="24"
          v-infinite-scroll="request"
          :infinite-scroll-distance="10"
          class="infinite-box"
        >
          <div v-for="(item, index) in collection.res" :key="index">
            <div class="flex align-center message-card" @click="toDetail(item)">
              <div
                class="rel flex-sub flex flex-direction justify-between card-context"
              >
                <div class="text-bold text-title">{{ item.title }}</div>
                <div style="font-size: 14px; color: #c2c2c2;">
                  {{ item.releaseTime.split(" ")[0] }}
                </div>
              </div>

              <div class="rel card-pic">
                <el-image
                  mode="aspectFill"
                  :src="item.coverImg"
                  style="width: 100%; height: 100%;flex-shrink: 0;"
                ></el-image>
              </div>
            </div>
          </div>
          <div v-if="collection.isNone" class="text-center no-more-text">
            没有更多了
          </div>
        </el-col>
      </el-row>
    </template>

    <template v-else>
      <el-row>
        <el-col :sm="24" :lg="24">
          <div
            class="flex flex-direction justify-center align-center no-data-box"
          >
            <el-image
              fit="contain"
              :src="require('@/assets/img/common/nodata.png')"
              style="height: 15rem;"
            ></el-image>
            <div class="no-data-text">
              暂无内容
            </div>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import { request } from "@/utils/request";

export default {
  name: "VCFrontier",
  data() {
    return {
      // 返回数据
      collection: {
        res: [],
        page: 1,
        limit: 10,
        // 是否正在下拉加载
        isLoading: false,
        // 是否已经加载完成
        isNone: false
      }
    };
  },
  mounted() {
    this.request();
  },
  methods: {
    // 初始化数据列表
    init() {
      this.collection = {
        res: [],
        page: 1,
        limit: 10,
        // 是否正在下拉加载
        isLoading: false,
        // 是否已经加载完成
        isNone: false
      };
    },
    // 请求接口
    request(isCover = false) {
      return new Promise((resolve, reject) => {
        if (this.collection.isLoading) return;

        this.collection.isLoading = true;
        let url =
          "https://xinke.thinkervc.cn/api/applet/activityCms/getActivityCmsList";
        url +=
          "?pageNum=" +
          this.collection.page +
          "&pageSize=" +
          this.collection.limit;

        request(url, {}).then(res => {
          if (res.code === 200) {
            if (
              Math.ceil(res.data.total / this.collection.limit) >=
                this.collection.page ||
              res.data.total === 0
            ) {
              let newData = res.data.records;
              if (isCover) {
                this.collection.res = newData;
              } else {
                newData.forEach((v, k) => {
                  this.collection.res.push(v);
                });
              }

              if (
                Math.ceil(res.data.total / this.collection.limit) ===
                this.collection.page
              ) {
                this.collection.isNone = true;
                this.collection.isLoading = false;
                resolve();
              }

              this.collection.page++;
            } else {
              this.collection.isNone = true;
            }
            this.collection.isLoading = false;
            resolve();
          } else {
            this.collection.isLoading = false;
            reject();
          }
        });
      });
    },

    toDetail(item) {
      this.$router.push({
        name: "VCFrontierDetail",
        query: { id: item.id, type: 1 }
      });
    }
  },
  computed: {
    // 计算最新的开始时间
    startTime() {
      return function(s) {
        let res = s.split(" ");
        let time = res[1].split(":");
        let hours = time[0];
        let minutes = time[1].split(":")[0];
        return res[0] + " " + hours + ":" + minutes;
      };
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100vh;

  .rel {
    position: relative;
  }

  /* css */
  .infinite-box {
    width: 100%;
    height: 100%;
    overflow: auto;

    .message-card {
      margin: 0 auto;
      width: 92%;
      height: 7.5rem;
      color: #333;
      border-bottom: 1px solid #f3f3f3;

      .card-pic {
        margin-left: 1rem;
        width: 6.25rem;
        height: 6rem;
        border-radius: 0.125rem;
        overflow: hidden;
      }

      .card-context {
        width: 50%;
        height: 6rem;

        .text-title {
          padding-top: 0.25rem;
          width: 100%;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .no-more-text {
      padding: 1rem;
      color: #666;
    }
  }

  .no-data-box {
    padding: 8rem 4rem;
    width: 100%;
    height: 100%;

    .no-data-text {
      margin-top: 5%;
      font-size: 16px;
      font-weight: bold;
      color: #666;
    }
  }
}
</style>
